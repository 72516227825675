.app-download {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #070a37;
  width: 450px;
  position: relative;
  margin-top: 83.7px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-right-radius: 5%; 
  border-bottom-left-radius: 5%;
  box-shadow: 1px 2px 14px 5px #00000070;
  padding: 1rem;
  flex-direction: row;
}

.app-download label {
  margin: 0 0.5rem;
  color: #fff;
  cursor: pointer;
}

.app-download label#first-title,
.app-download label#second-title {
  font-size: 1.3rem;
}

.picture {
  width: 128px;
  height: 64px;
}

@media only screen and (max-width: 1480px) {
  .app-download {
    padding: 0.4rem;
  }
  .picture {
    width: 120px;
    height: 60px;
  }
  .app-download label#first-title,
  .app-download label#second-title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .app-download {
    padding: 0.5rem;
  }
  .app-download label {
    margin: 0 1.8rem; 
  }
  .picture {
    width: 110px;
    height: 55px;
  }
}

@media only screen and (max-width: 992px) {
  .app-download {
    padding: 0.4rem;
  }
  .app-download label {
    margin: 0 2.6rem;
  }
}

@media only screen and (max-width: 768px) {
  .app-download {
    padding: 0.3rem;
  }
  .app-download label {
    margin: 0 2.8rem;
  }
}

@media only screen and (max-width: 576px) {
  .app-download {
    flex-direction: column;
    width: 140px;
    padding-top: 15px;
    padding-bottom: 10px;
 
  }
  .picture {
    width: 90px;
    height: 45px;
  }
  .app-download label#first-title,
  .app-download label#second-title {
    margin-top: 4px;
    font-size: 1.1rem;
  }
  .order-1 { order: 1; }
  .order-2 { order: 2; }
  .order-3 { order: 3; }
}

@media only screen and (max-width: 400px) {
  .app-download {
    padding: 0.2rem;
    padding-top: 15px;
    width: 110px;
    padding-bottom: 10px;

  }
  .picture {
    width: 80px;
    height: 40px;
  }
  .app-download label#first-title,
  .app-download label#second-title {
    font-size: 0.8rem;
    font-weight: bold;
  }
  .app-download label#second-title {
    padding-bottom: 10px;
  }
}