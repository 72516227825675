
.badges {
    width: 200px;
    height: 200px;
    background: #0b1056;
    border-radius: 100%;
    color: #FFF;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    position: relative;
    animation: swing 3s ease-in-out infinite;

    /* Centering adjustments */
    margin: 0; /* Reset margin */
    transform-origin: 100px 100px; /* Adjust transform-origin for proper swinging */
}

.badges:before {
    content: '';
    position: absolute;
    top: 90px;
    left: 90px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    box-shadow: 0px -82px 0px -2px #fff, 
                0px -100px #0b1056, 
                20px -98px #04072f, 
                39px -94px #0b1056, 
                56px -85px #0b1056, 
                71px -72px #0b1056,
                83px -57px #0b1056, 
                93px -40px #0b1056, 
                98px -20px #0b1056, 
                100px 0px #0b1056,
                -20px -98px #0b1056, 
                -39px -94px #0b1056, 
                -71px -72px #0b1056, 
                -56px -85px #0b1056, 
                -83px -57px #0b1056, 
                -93px -40px #0b1056, 
                -98px -20px #0b1056, 
                -100px 0px #0b1056,
                0px 100px #0b1056, 
                -20px 98px #0b1056, 
                -39px 94px #0b1056, 
                -56px 85px #0b1056, 
                -71px 72px #0b1056, 
                -83px 57px #0b1056, 
                -93px -40px #0b1056, 
                -98px 20px #0b1056, 
                -93px 40px #0b1056,
                20px 98px #0b1056, 
                39px 94px #0b1056, 
                56px 85px #0b1056, 
                71px 72px #0b1056, 
                83px 57px #0b1056, 
                93px 40px #0b1056, 
                98px 20px #0b1056;
}

.badges:after {
    content: '';
    position: absolute;
    top: -70px;
    left: 99px;
    width: 2px;
    height: 81px;
    background: #000;
}

@keyframes swing {
    0% { transform: rotate(5deg); }
    50% { transform: rotate(-5deg); }
    100% { transform: rotate(5deg); }
}